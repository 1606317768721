.section {
    display: flex;
    flex-direction: column;
}

.spinner {
    display: flex;
    justify-content: center;
}

.cardBody {
    height: 60vh;
    overflow-y: scroll;
}
