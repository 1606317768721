.TableWrapper {
    margin-bottom: 1.5rem;
    max-height: 60vh;
    overflow-x: scroll;
    overflow-y: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.TableWrapper .TableComponent {
    display: inline-block;
}

.dropdown ul.dropdown-menu {
    transform: translateY(-120%);
}

.ClickableTableRows tr {
    cursor: pointer;
}

@media( max-width: 767px ) {
    .react-bootstrap-table-pagination {
        flex-direction: column;
        align-items: center;
    }
    .react-bootstrap-table-pagination-list {
        padding-top: 1rem;
    }
    .react-bootstrap-table-page-btns-ul {
        justify-content: center;
    }
}

@media ( min-width: 768px ) {
    .react-bootstrap-table-pagination {
        padding-top: 1.5rem !important;
    }
}
