a.AddNew:hover .addButtonIcon {
    fill: #fff;
}

a.AddNew:hover:disabled {
    cursor: not-allowed;
}

.AddButtonText {
    vertical-align: middle;
}
