.leaderboard-select {
    position: relative !important;
    min-width: 150px;
    max-width: 400px;
    left: 30px;
    width: 75% !important;
}

.leaderboard-criteria-select {
    margin-top: 1.5rem !important;
}

@media( min-width: 768px ) {
    .leaderboard-select {
        width: 35% !important;

    }
    .leaderboard-criteria-select {
        margin-top: 0 !important;
        left: 50px;
    }
}
