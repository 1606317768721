.Paragraph {
  font-weight: bold;
}

.SideDrawerOpen {
  right: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  left: 0;
  top: 0;
  height: 100%;
}

@media( min-width: 768px ) {
  .SideDrawerOpen {
    margin-left: 300px;
  }
}

.MainContainer {
  overflow-y: scroll;
  height: 100%;
  margin-top: 15px;
}

.Loading {
  opacity: 0.3;
}

.HoverCursor:hover {
  cursor: pointer;
  font-weight: bold;
}

.HoverColor:hover {
  color: #007bff;
}

.TimelineKey {
  text-transform: capitalize;
}

.MainPadding {
  padding: 0 30px;
}
