.rotated {
    transform: rotateY(180deg);
}
.FilterIcon {
    transition: all 1s;
}

.NavItems {
    font-size: 1rem;
}

@media( min-width: 768px ) {
    .NavItems {
        font-size: 0.7rem;
    }
}

@media( min-width: 840px ) {
    .NavItems {
        font-size: 0.8rem;
    }
}

@media( min-width: 970px ) {
    .NavItems {
        font-size: 1rem;
    }
}

@media( max-width: 767px ) {
    .CollapsibleContainer {
        max-height: calc(100vh - 65px);
        overflow-y: scroll;
        position: absolute !important;
        width: 100vw;
        top: 65px;
    }
    .MobileMarginLeft {
        margin-left: calc(50% - 190px);
    }
    .MobileLogoSpacing {
        width: calc(50% + 33px);
    }
    .LogoSize {
        height: 40px;
    }
}

.UserDropdownContainer {
    max-height: calc(100vh - 65px);
    overflow-y: scroll;
}

.NotificationDropdownContainer {
    top: 5px !important;
}

.NotificationDropdownMenu {
    width: 17rem !important;
    right: -60px !important;
}

.NotificationBadge {
    left: 28px !important;
}

.NotificationDropdownFrom {
    font-size: 11px;
}
