.note-text-area {
    white-space: pre-wrap;
    width: 80%;
}

.note-user-notifications {
    min-width: 300px;
    white-space: pre;
}

.AddNew:hover:enabled .addButtonIcon {
    fill: #fff;
}

.AddNew:hover:disabled {
    cursor: not-allowed;
}

.vertical-padding {
    padding: 1.25rem 0 !important;
}
