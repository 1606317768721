div.form-group.left-label {
    text-align: left;
}

a.tab-item:first-of-type button.btn-lg {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

a.tab-item {
    border-right: none;
    z-index: 0;
}

.list-group-item.active {
    z-index: 0;
}

a.tab-item button.btn-lg {
    height: 105%;
    border-radius: 0.25rem;
}

.table td, .table th {
    vertical-align: middle !important;
    white-space: pre;
}

.list-group-item.active {
    background-color: #dadada;
    font-weight: bold;
    border: none;
    color: #495057;
}

.nav-tabs a.nav-link {
    text-decoration: none;
    color: black;
    background: #fff;
    border: 1px solid #000;
    border-bottom: 1px solid #dee2e6;
}

.nav-tabs a.nav-link:hover {
    background: #dadada;
    border-bottom: 1px solid #dee2e6;
}

.nav-tabs a.nav-link.active {
    font-weight: bold;
    background: #dadada;
    border-bottom: 1px solid #dee2e6;
}

#user-dropdown:after, #notification-dropdown:after {
    content: none
}

.invalid-react-select > div {
    border: 1px solid #dc3545;
}

.modal-dialog.h-100 > div.modal-content {
    height: 100vh;
}

.pre-wrap {
    white-space: pre-wrap;
}

main {
    position: relative;
    top: 65px;
    height: calc(100vh - 65px);
    overflow: hidden;
}

.top-nav {
    height: 65px;
}

.nav-offset {
    position: relative;
    top: 65px;
}

@media ( max-width: 767px ) {
    .add-member-container {
        padding-top: 1rem !important;
    }

    .add-manager-container {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .navbar-nav a.nav-link {
        font-size: 20px;
    }
}

@media ( max-width: 575px ) {
    .xs-pt-3 {
        padding-top: 1rem !important;
    }
}

.remove-customer-button-container {
    border: none;
}

.remove-customer-button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding: 21px;
}

.tab-error {
    border-color: #f5c2c7 #f5c2c7 #f8d7da #f5c2c7 !important;
    border-width: 2px !important;
    color: #842029 !important;
    background-color: #f8d7da !important;
}

.css-26l3qy-menu {
    z-index: 100 !important;
}

.auth-container {
    width: 100%;
}

@media (min-width: 768px) {
    .auth-container {
        width: 50%;
    }
}

.b-width {
    width: 100%;
}
@media (min-width: 576px) {
    .b-width {
        width: auto;
    }
}
