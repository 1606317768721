.LoadingIcon {
    font-size: 25px;
    margin-right: 5px;
    animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.StarFull {
    cursor: pointer;
    fill: #ee0;
    font-size: 25px;
    margin-right: 5px;
    stroke: #dadada;
    stroke-width: 1px;
}

.Star {
    cursor: pointer;
    font-size: 25px;
    margin-right: 5px;
}
