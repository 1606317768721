.SideDrawer {
    position: absolute;
    width: 327px;
    max-width: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: transparent;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    height:100%;
    right: 0;

}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(calc(-100% + 27px));
}

.SideDrawerContainer {
    width: 300px;
    padding: 32px 16px;
    overflow-y: scroll;
    border-right: 1px solid black;
    height: 100%;
    max-width: 92%;
    background-color: #FFF;
}

.SideDrawerToggleContainer {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%) rotate(90deg);
    transform-origin: top left;
}

.SideDrawerToggleButton {
    background-color: #FFF;
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    border-bottom: 2px solid #FFF;
    border-left: none;
    text-transform: capitalize;
}

.Capitalize {
    text-transform: capitalize;
}
