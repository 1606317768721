.rbc-agenda-event-cell {
    white-space: pre;
}

.rbc-calendar {
    height: 80vh;
}

.rbc-time-header {
    height: 100px;
    overflow-y: scroll;
}

.rbc-time-content {
    height: 500px;
    overflow-y: scroll;
}

.rbc-toolbar-label {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.full-width {
    width: 100%;
}

.expanded-width {
    width: 500%;
}

.loading {
    opacity: 0.3;
}

.spinner-div {
    position: absolute;
    top: 50%;
    z-index: 5;
    left: 50%;
}

.schedule-legend div div {
    width: 20px;
    height: 20px;
}

@media( max-width: 767px ) {
    .schedule-legend div p {
        font-size: 12px;
        margin-bottom: 5px;
    }
    .schedule-legend div div {
        width: 12px;
        margin-top: 2px;
        height: 12px;
    }
}

@media( max-width: 849px) {
    .rbc-toolbar {
        flex-direction: column;
    }
}
